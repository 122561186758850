import * as coreClient from "@azure/core-client";

export const Contract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Contract",
    modelProperties: {
      code: {
        serializedName: "code",
        required: true,
        type: {
          name: "String",
        },
      },
      options: {
        serializedName: "options",
        type: {
          name: "Composite",
          className: "CompileOpts",
        },
      },
    },
  },
};

export const CompileOpts: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CompileOpts",
    modelProperties: {
      fileSystem: {
        serializedName: "file_system",
        type: {
          name: "Dictionary",
          value: { type: { name: "any" } },
        },
      },
      srcFile: {
        serializedName: "src_file",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CompilerError: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CompilerError",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      pos: {
        serializedName: "pos",
        type: {
          name: "Composite",
          className: "ErrorPos",
        },
      },
      message: {
        serializedName: "message",
        required: true,
        type: {
          name: "String",
        },
      },
      context: {
        serializedName: "context",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ErrorPos: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ErrorPos",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String",
        },
      },
      line: {
        serializedName: "line",
        required: true,
        type: {
          name: "Number",
        },
      },
      col: {
        serializedName: "col",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const CompileResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CompileResult",
    modelProperties: {
      bytecode: {
        serializedName: "bytecode",
        required: true,
        type: {
          name: "String",
        },
      },
      aci: {
        serializedName: "aci",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Dictionary",
              value: { type: { name: "any" } },
            },
          },
        },
      },
      warnings: {
        serializedName: "warnings",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CompilerError",
            },
          },
        },
      },
    },
  },
};

export const SophiaCallResultInput: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SophiaCallResultInput",
    modelProperties: {
      source: {
        serializedName: "source",
        required: true,
        type: {
          name: "String",
        },
      },
      options: {
        serializedName: "options",
        type: {
          name: "Composite",
          className: "CompileOpts",
        },
      },
      function: {
        serializedName: "function",
        required: true,
        type: {
          name: "String",
        },
      },
      callResult: {
        serializedName: "call-result",
        required: true,
        type: {
          name: "String",
        },
      },
      callValue: {
        serializedName: "call-value",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const BytecodeCallResultInput: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BytecodeCallResultInput",
    modelProperties: {
      bytecode: {
        serializedName: "bytecode",
        required: true,
        type: {
          name: "String",
        },
      },
      function: {
        serializedName: "function",
        required: true,
        type: {
          name: "String",
        },
      },
      callResult: {
        serializedName: "call-result",
        required: true,
        type: {
          name: "String",
        },
      },
      callValue: {
        serializedName: "call-value",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DecodedCallresult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DecodedCallresult",
    modelProperties: {
      function: {
        serializedName: "function",
        required: true,
        type: {
          name: "String",
        },
      },
      result: {
        serializedName: "result",
        required: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "any" } },
        },
      },
    },
  },
};

export const FunctionCallInput: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FunctionCallInput",
    modelProperties: {
      source: {
        serializedName: "source",
        required: true,
        type: {
          name: "String",
        },
      },
      options: {
        serializedName: "options",
        type: {
          name: "Composite",
          className: "CompileOpts",
        },
      },
      function: {
        serializedName: "function",
        required: true,
        type: {
          name: "String",
        },
      },
      arguments: {
        serializedName: "arguments",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const Calldata: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Calldata",
    modelProperties: {
      calldata: {
        serializedName: "calldata",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DecodeCalldataBytecode: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DecodeCalldataBytecode",
    modelProperties: {
      calldata: {
        serializedName: "calldata",
        required: true,
        type: {
          name: "String",
        },
      },
      bytecode: {
        serializedName: "bytecode",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DecodedCalldata: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DecodedCalldata",
    modelProperties: {
      function: {
        serializedName: "function",
        required: true,
        type: {
          name: "String",
        },
      },
      arguments: {
        serializedName: "arguments",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Dictionary",
              value: { type: { name: "any" } },
            },
          },
        },
      },
    },
  },
};

export const ErrorModel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ErrorModel",
    modelProperties: {
      reason: {
        serializedName: "reason",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DecodeCalldataSource: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DecodeCalldataSource",
    modelProperties: {
      source: {
        serializedName: "source",
        required: true,
        type: {
          name: "String",
        },
      },
      options: {
        serializedName: "options",
        type: {
          name: "Composite",
          className: "CompileOpts",
        },
      },
      calldata: {
        serializedName: "calldata",
        required: true,
        type: {
          name: "String",
        },
      },
      function: {
        serializedName: "function",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ByteCodeInput: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ByteCodeInput",
    modelProperties: {
      bytecode: {
        serializedName: "bytecode",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const FateAssembler: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FateAssembler",
    modelProperties: {
      fateAssembler: {
        serializedName: "fate-assembler",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ValidateByteCodeInput: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ValidateByteCodeInput",
    modelProperties: {
      bytecode: {
        serializedName: "bytecode",
        required: true,
        type: {
          name: "String",
        },
      },
      source: {
        serializedName: "source",
        required: true,
        type: {
          name: "String",
        },
      },
      options: {
        serializedName: "options",
        type: {
          name: "Composite",
          className: "CompileOpts",
        },
      },
    },
  },
};

export const CompilerVersion: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CompilerVersion",
    modelProperties: {
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ApiVersion: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ApiVersion",
    modelProperties: {
      apiVersion: {
        serializedName: "api-version",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SophiaBinaryData: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SophiaBinaryData",
    modelProperties: {
      sophiaType: {
        serializedName: "sophia-type",
        required: true,
        type: {
          name: "String",
        },
      },
      data: {
        serializedName: "data",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SophiaJsonData: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SophiaJsonData",
    modelProperties: {
      data: {
        serializedName: "data",
        required: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "any" } },
        },
      },
    },
  },
};
