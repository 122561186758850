import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  CompilerOptionalParams,
  Contract,
  GenerateACIOptionalParams,
  GenerateACIResponse,
  CompileContractOptionalParams,
  CompileContractResponse,
  SophiaCallResultInput,
  DecodeCallResultOptionalParams,
  DecodeCallResultResponse,
  BytecodeCallResultInput,
  DecodeCallResultBytecodeOptionalParams,
  DecodeCallResultBytecodeResponse,
  FunctionCallInput,
  EncodeCalldataOptionalParams,
  EncodeCalldataResponse,
  DecodeCalldataBytecode,
  DecodeCalldataBytecodeOptionalParams,
  DecodeCalldataBytecodeResponse,
  DecodeCalldataSource,
  DecodeCalldataSourceOptionalParams,
  DecodeCalldataSourceResponse,
  ByteCodeInput,
  GetFateAssemblerCodeOptionalParams,
  GetFateAssemblerCodeResponse,
  ValidateByteCodeInput,
  ValidateByteCodeOptionalParams,
  GetCompilerVersionOptionalParams,
  GetCompilerVersionResponse,
  VersionOptionalParams,
  VersionResponse,
  ApiVersionOptionalParams,
  ApiVersionResponse,
  ApiOptionalParams,
  ApiResponse,
} from "./models";

export class Compiler extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the Compiler class.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor($host: string, options?: CompilerOptionalParams) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: CompilerOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-compiler/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);
    // Parameter assignments
    this.$host = $host;
  }

  /**
   * Generate an Aeternity Contract Interface (ACI) for contract
   * @param body contract code
   * @param options The options parameters.
   */
  generateACI(
    body: Contract,
    options?: GenerateACIOptionalParams,
  ): Promise<GenerateACIResponse> {
    return this.sendOperationRequest(
      { body, options },
      generateACIOperationSpec,
    );
  }

  /**
   * Compile a sophia contract from source and return byte code and ACI
   * @param body contract code
   * @param options The options parameters.
   */
  compileContract(
    body: Contract,
    options?: CompileContractOptionalParams,
  ): Promise<CompileContractResponse> {
    return this.sendOperationRequest(
      { body, options },
      compileContractOperationSpec,
    );
  }

  /**
   * Decode the result of contract call
   * @param body Binary data in Sophia ABI format
   * @param options The options parameters.
   */
  decodeCallResult(
    body: SophiaCallResultInput,
    options?: DecodeCallResultOptionalParams,
  ): Promise<DecodeCallResultResponse> {
    return this.sendOperationRequest(
      { body, options },
      decodeCallResultOperationSpec,
    );
  }

  /**
   * Decode the result of contract call from Bytecode
   * @param body Call result + compiled contract
   * @param options The options parameters.
   */
  decodeCallResultBytecode(
    body: BytecodeCallResultInput,
    options?: DecodeCallResultBytecodeOptionalParams,
  ): Promise<DecodeCallResultBytecodeResponse> {
    return this.sendOperationRequest(
      { body, options },
      decodeCallResultBytecodeOperationSpec,
    );
  }

  /**
   * Encode Sophia function call according to sophia ABI.
   * @param body Sophia function call - contract code + function name + arguments
   * @param options The options parameters.
   */
  encodeCalldata(
    body: FunctionCallInput,
    options?: EncodeCalldataOptionalParams,
  ): Promise<EncodeCalldataResponse> {
    return this.sendOperationRequest(
      { body, options },
      encodeCalldataOperationSpec,
    );
  }

  /**
   * Identify function name and arguments in Calldata for a compiled contract
   * @param body Calldata + compiled contract
   * @param options The options parameters.
   */
  decodeCalldataBytecode(
    body: DecodeCalldataBytecode,
    options?: DecodeCalldataBytecodeOptionalParams,
  ): Promise<DecodeCalldataBytecodeResponse> {
    return this.sendOperationRequest(
      { body, options },
      decodeCalldataBytecodeOperationSpec,
    );
  }

  /**
   * Identify function name and arguments in Calldata for a (partial) contract
   * @param body Calldata + contract (stub) code
   * @param options The options parameters.
   */
  decodeCalldataSource(
    body: DecodeCalldataSource,
    options?: DecodeCalldataSourceOptionalParams,
  ): Promise<DecodeCalldataSourceResponse> {
    return this.sendOperationRequest(
      { body, options },
      decodeCalldataSourceOperationSpec,
    );
  }

  /**
   * Get FATE assembler code from bytecode
   * @param body contract byte array
   * @param options The options parameters.
   */
  getFateAssemblerCode(
    body: ByteCodeInput,
    options?: GetFateAssemblerCodeOptionalParams,
  ): Promise<GetFateAssemblerCodeResponse> {
    return this.sendOperationRequest(
      { body, options },
      getFateAssemblerCodeOperationSpec,
    );
  }

  /**
   * Verify that an encoded byte array is the result of compiling a given contract
   * @param body contract byte array and source code
   * @param options The options parameters.
   */
  validateByteCode(
    body: ValidateByteCodeInput,
    options?: ValidateByteCodeOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { body, options },
      validateByteCodeOperationSpec,
    );
  }

  /**
   * Extract compiler version from bytecode
   * @param body contract byte array
   * @param options The options parameters.
   */
  getCompilerVersion(
    body: ByteCodeInput,
    options?: GetCompilerVersionOptionalParams,
  ): Promise<GetCompilerVersionResponse> {
    return this.sendOperationRequest(
      { body, options },
      getCompilerVersionOperationSpec,
    );
  }

  /**
   * Get the version of the underlying Sophia compiler version
   * @param options The options parameters.
   */
  version(options?: VersionOptionalParams): Promise<VersionResponse> {
    return this.sendOperationRequest({ options }, versionOperationSpec);
  }

  /**
   * Get the version of the API
   * @param options The options parameters.
   */
  apiVersion(options?: ApiVersionOptionalParams): Promise<ApiVersionResponse> {
    return this.sendOperationRequest({ options }, apiVersionOperationSpec);
  }

  /**
   * Get the Api description
   * @param options The options parameters.
   */
  api(options?: ApiOptionalParams): Promise<ApiResponse> {
    return this.sendOperationRequest({ options }, apiOperationSpec);
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const generateACIOperationSpec: coreClient.OperationSpec = {
  path: "/aci",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Dictionary", value: { type: { name: "any" } } },
          },
        },
      },
    },
    400: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "CompilerError" } },
        },
      },
      isError: true,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const compileContractOperationSpec: coreClient.OperationSpec = {
  path: "/compile",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CompileResult,
    },
    400: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "CompilerError" } },
        },
      },
      isError: true,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const decodeCallResultOperationSpec: coreClient.OperationSpec = {
  path: "/decode-call-result",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "any" } },
    },
    400: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "CompilerError" } },
        },
      },
      isError: true,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const decodeCallResultBytecodeOperationSpec: coreClient.OperationSpec = {
  path: "/decode-call-result/bytecode",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DecodedCallresult,
    },
    400: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "CompilerError" } },
        },
      },
      isError: true,
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const encodeCalldataOperationSpec: coreClient.OperationSpec = {
  path: "/encode-calldata",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.Calldata,
    },
    400: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "CompilerError" } },
        },
      },
      isError: true,
    },
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const decodeCalldataBytecodeOperationSpec: coreClient.OperationSpec = {
  path: "/decode-calldata/bytecode",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DecodedCalldata,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const decodeCalldataSourceOperationSpec: coreClient.OperationSpec = {
  path: "/decode-calldata/source",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DecodedCalldata,
    },
    400: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "CompilerError" } },
        },
      },
      isError: true,
    },
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getFateAssemblerCodeOperationSpec: coreClient.OperationSpec = {
  path: "/fate-assembler",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.FateAssembler,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  requestBody: Parameters.body6,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const validateByteCodeOperationSpec: coreClient.OperationSpec = {
  path: "/validate-byte-code",
  httpMethod: "POST",
  responses: {
    200: {},
    400: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "CompilerError" } },
        },
      },
      isError: true,
    },
  },
  requestBody: Parameters.body7,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getCompilerVersionOperationSpec: coreClient.OperationSpec = {
  path: "/compiler-version",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CompilerVersion,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  requestBody: Parameters.body6,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const versionOperationSpec: coreClient.OperationSpec = {
  path: "/version",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.CompilerVersion,
    },
    500: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const apiVersionOperationSpec: coreClient.OperationSpec = {
  path: "/api-version",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ApiVersion,
    },
    500: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const apiOperationSpec: coreClient.OperationSpec = {
  path: "/api",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } },
      },
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
