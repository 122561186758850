import { OperationParameter, OperationURLParameter } from "@azure/core-client";
import {
  Contract as ContractMapper,
  SophiaCallResultInput as SophiaCallResultInputMapper,
  BytecodeCallResultInput as BytecodeCallResultInputMapper,
  FunctionCallInput as FunctionCallInputMapper,
  DecodeCalldataBytecode as DecodeCalldataBytecodeMapper,
  DecodeCalldataSource as DecodeCalldataSourceMapper,
  ByteCodeInput as ByteCodeInputMapper,
  ValidateByteCodeInput as ValidateByteCodeInputMapper,
} from "../models/mappers";

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: ContractMapper,
};

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const body1: OperationParameter = {
  parameterPath: "body",
  mapper: SophiaCallResultInputMapper,
};

export const body2: OperationParameter = {
  parameterPath: "body",
  mapper: BytecodeCallResultInputMapper,
};

export const body3: OperationParameter = {
  parameterPath: "body",
  mapper: FunctionCallInputMapper,
};

export const body4: OperationParameter = {
  parameterPath: "body",
  mapper: DecodeCalldataBytecodeMapper,
};

export const body5: OperationParameter = {
  parameterPath: "body",
  mapper: DecodeCalldataSourceMapper,
};

export const body6: OperationParameter = {
  parameterPath: "body",
  mapper: ByteCodeInputMapper,
};

export const body7: OperationParameter = {
  parameterPath: "body",
  mapper: ValidateByteCodeInputMapper,
};
